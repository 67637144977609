import { LocationClientAPI } from 'db/types-client-api';
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';

export interface LocationContextType {
  location: Partial<LocationClientAPI>;
}

const LocationContextTypeDefaultValues: LocationContextType = {
  location: {},
};

export const LocationContext = createContext<LocationContextType>(
  LocationContextTypeDefaultValues
);
export const LocationDispatchContext = createContext<any>(undefined);

export const useLocation = (): LocationContextType => {
  const context = useContext(LocationContext);
  return context;
};

type Props = {
  children: ReactNode;
};

export const LocationContextProvider = ({ children }: Props): ReactElement => {
  const [value, setValue] = useState<LocationContextType>(
    LocationContextTypeDefaultValues
  );

  return (
    <LocationContext.Provider value={value}>
      <LocationDispatchContext.Provider value={setValue}>
        {children}
      </LocationDispatchContext.Provider>
    </LocationContext.Provider>
  );
};
