import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MenuType, OrderClientType } from 'front/db/types-client-api';

export interface OrderContextType {
  orders: any;
  menu: MenuType | undefined;
}

const OrderContextTypeDefaultValues: OrderContextType = {
  orders: undefined,
  menu: undefined,
};

export const OrderContext = createContext<OrderContextType>(
  OrderContextTypeDefaultValues
);
export const OrderDispatchContext = createContext<any>(undefined);

export const useOrder = (): OrderContextType => {
  const context = useContext(OrderContext);
  return context;
};

type Props = {
  children: ReactNode;
};

export const OrderProvider = ({ children }: Props): ReactElement => {
  const [value, setValue] = useState<OrderContextType>(
    OrderContextTypeDefaultValues
  );

  return (
    <OrderContext.Provider value={value}>
      <OrderDispatchContext.Provider value={setValue}>
        {children}
      </OrderDispatchContext.Provider>
    </OrderContext.Provider>
  );
};
