import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';

export interface MenuItemSelectContextType {
  menuData: any;
}

const MenuItemSelectTypeDefaultValues: MenuItemSelectContextType = {
  menuData: {},
};

export const MenuItemSelectContext = createContext<MenuItemSelectContextType>(
  MenuItemSelectTypeDefaultValues
);
export const MenuItemSelectDispatchContext = createContext<any>(undefined);

export const useMenuItemSelect = (): MenuItemSelectContextType => {
  const context = useContext(MenuItemSelectContext);
  return context;
};

type Props = {
  children: ReactNode;
};

export const MenuItemSelectContextProvider = ({
  children,
}: Props): ReactElement => {
  const [value, setValue] = useState<MenuItemSelectContextType>(
    MenuItemSelectTypeDefaultValues
  );

  return (
    <MenuItemSelectContext.Provider value={value}>
      <MenuItemSelectDispatchContext.Provider value={setValue}>
        {children}
      </MenuItemSelectDispatchContext.Provider>
    </MenuItemSelectContext.Provider>
  );
};
